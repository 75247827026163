import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import BlankContentContainer from "../../containers/BlankContentContainer/BlankContentContainer";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

type BlankProps = {
  wpPage: {
    seo: YoastSEOType;
    title: string;
    content: string;
  };
};

const BlankPage: React.FC<PageProps<BlankProps>> = ({ data }) => {
  const { wpPage } = data;
  const { content } = wpPage;

  return (
    <MainLayout>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <BlankContentContainer content={content} />
    </MainLayout>
  );
};

export default BlankPage;

export const query = graphql`
  query BlankTemplate($id: String) {
    wpPage(id: { eq: $id }) {
      id
      content
      ...YoastSEOFragment
    }
  }
`;
