import * as React from "react";
import { useEffect, useState } from "react";
import { BlankContentType } from "../../assets/Types/AcfGroupTypes/BlankContent.type";
import parse from "html-react-parser";
import { Element } from "domhandler/lib/node";

type ParsedHtml = string | JSX.Element | JSX.Element[];

const BlankContentContainer: React.FC<BlankContentType> = ({ content }) => {
  const [scriptSrc, setScriptSrc] = useState<string>();
  const [html, setHtml] = useState<ParsedHtml>();

  useEffect(() => {
    const wysiwyg = parse(content, {
      replace: node => {
        if (node instanceof Element && node.type === "script") {
          if (node.attribs.src) {
            setScriptSrc(node.attribs.src);
            return;
          } else {
            if (scriptSrc) {
              const script = document.createElement("script");
              script.src = scriptSrc;
              script.onload = function () {
                Function(node.children[0]["data"])();
              };
              document.body.append(script);
            }
            return;
          }
        }
        return node;
      },
    });
    !html && setHtml(wysiwyg);
  }, [html]);

  return <section>{html}</section>;
};

export default BlankContentContainer;
